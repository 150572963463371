import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload, Space } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IAppointment } from '../../../interfaces/patient';
import { API_BASE_URL } from '../../../apiService/apiservices';
import { updateLanguageServiceSourceFile } from 'typescript';

const { Option } = Select;

type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Edit Advertisement';
const emptyAppointment = {
  date: '',
  doctor: '',
  email: '',
  fromTo: '',
  img: '',
  injury: '',
  name: '',
  number: '',
  from: '',
  to: '',
  tender_number: '',
  id: '',
  tender_closetime: '',
  prebid_date: '',
  prebid_time: '',
  prebid_closedate: '',
  prebid_closetime: '',
  Remarks: '',
  opening_date: '',
  Close_date: ''
};

const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required(),
  id: Yup.string().required(),
  tender_closetime: Yup.string().required(),
  opening_date: Yup.string().required(),
  Close_date: Yup.string().required(),
  Remarks: Yup.string().required(),
  prebid_closetime: Yup.string().required(),
  prebid_closedate: Yup.string().required(),
  prebid_time: Yup.string().required(),
  prebid_date: Yup.string().required()
});

const AdvertisementFormEdit = ({
  submitText = defaultSubmitText,
  appointment,
  onSubmit,
  onCancel
}: Props) => {
  const [tenderDocument, setTenderDocument] = useState([]);
  const [corrigendums, setCorrigendums] = useState([]);
  const [result, setResult] = useState([]);

  const formik = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}`, img: '' });
      formik.resetForm();
    }
  });

  const handleTenderDocumentChange = ({ fileList }) => setTenderDocument(fileList);

  const [advertisementDocument, setaAdvertisementDocument] = useState([]);

  const handleCorrigendumChange =
    (index: number) =>
    ({ fileList }) => {
      const newCorrigendums = [...corrigendums];
      newCorrigendums[index].file = fileList;
      setCorrigendums(newCorrigendums);
    };

  const addCorrigendum = () => {
    const newCorrigendums = [...corrigendums, {}]; // Add an empty object for new corrigendum
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const removeCorrigendum = (index: number) => {
    const newCorrigendums = corrigendums.filter((_, i) => i !== index);
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const normFile = (e: any) => (Array.isArray(e) ? e : e && e.fileList);

  useEffect(() => {
    formik.setFieldValue('corrigendums', corrigendums);
  }, [corrigendums]);

  // for results
  const handleResultChange =
    (index: number) =>
    ({ fileList }) => {
      const newResult = [...result];
      newResult[index].file = fileList;
      setResult(newResult);
    };

  const addResult = () => {
    const newResult = [...result, {}]; // Add an empty object for new corrigendum
    setResult(newResult);
    formik.setFieldValue('newResult', newResult); // Update Formik's values
  };

  const removeResult = (index: number) => {
    const newResult = result.filter((_, i) => i !== index);
    setResult(newResult);
    formik.setFieldValue('result', setResult); // Update Formik's values
  };

  useEffect(() => {
    formik.setFieldValue('result', result);
  }, [result]);
  //  ---------------------------  end result
  const onUpdate = async (fieldsValue: any) => {
    // console.log('from update---', fieldsValue['advt_name']);

    const values = {
      ...fieldsValue,
      advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
      advt_opentime: fieldsValue['advt_opentime']
        ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
        : '',
      advt_closetime: fieldsValue['advt_closetime']
        ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
        : '',
      advt_opendate: fieldsValue['advt_opendate']
        ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
        : '',
      advt_closedate: fieldsValue['advt_closedate']
        ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
        : '',

      advt_document:
        fieldsValue['advt_document'] && fieldsValue['advt_document'][0]
          ? fieldsValue['advt_document'][0].name
          : '',
      advt_name:
        fieldsValue['advt_name'] && fieldsValue['advt_name'] ? fieldsValue['advt_name'] : '',

      advt_notices: corrigendums.map((item) => ({
        title: item.title,
        document: item.file && item.file[0] ? item.file[0].name : ''
      })),
      advt_result: result.map((item) => ({
        title: item.title,
        document: item.file && item.file[0] ? item.file[0].name : ''
      }))
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/aiims_advertisement_table/update/${appointment?._id}`,
        values,
        {
          withCredentials: true
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert('updated successfully!');
        onCancel();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <Card>
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{ remember: true }}
        onFinish={onUpdate}
      >
        <Form.Item
          label='Advertisement Number'
          name='advt_id'
          rules={[
            {
              // required: true,
              message: 'Please enter your Advertisement Number'
            }
          ]}
        >
          <Input placeholder='Advertisement Number' defaultValue={appointment?.advt_id} />
        </Form.Item>
        <Form.Item
          label='Advertisement Name'
          name='advt_name'
          rules={[
            {
              // required: true,
              message: 'Please enter your Advertisement Name'
            }
          ]}
        >
          <Input
            style={{ marginLeft: '15px' }}
            placeholder='Advertisement Name'
            defaultValue={appointment?.advt_name}
          />
        </Form.Item>

        <Form.Item
          name='advt_document'
          label='Advertisement Document'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          //   rules={[{
          //     // required: true,
          //      message: 'Please upload Advertisement Document' }]}
          // extra="long"
        >
          <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
            <Button>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name='addl_advt_doc'
          label='Additional Document'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          // extra="long"
        >
          <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
            <Button style={{ marginLeft: '3rem' }}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name='addl_advt_lebel'
          label='Additional Document Name'
          rules={[{ message: 'Please enter Additional Document Name' }]}
        >
          <Input
            placeholder='Additional Document Name'
            defaultValue={appointment?.addl_advt_lebel}
          />
        </Form.Item>

        <Form.List name='corrigendums'>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    marginBottom: 8
                  }}
                  direction='vertical'
                >
                  <Form.Item
                    {...restField}
                    label={`Notice ${index + 1}`}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    rules={[{ required: true, message: 'Missing Notice title' }]}
                    style={{ width: '100%' }}
                  >
                    <Input
                      placeholder='Notice Title'
                      defaultValue={corrigendums[index]?.title} // Set default value
                      onChange={(e) => {
                        const newCorrigendums = [...corrigendums];
                        newCorrigendums[index].title = e.target.value;
                        setCorrigendums(newCorrigendums);
                      }}
                    />
                  </Form.Item>

                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'document']}
                      fieldKey={[fieldKey, 'document']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[{ required: true, message: 'Missing notice file' }]}
                      style={{ flexGrow: 1 }}
                    >
                      <Upload
                        name='document'
                        listType='picture'
                        action={`${API_BASE_URL}/api/upload/`}
                        fileList={corrigendums[index]?.file}
                        onChange={handleCorrigendumChange(index)}
                        maxCount={1}
                        // style={{ textWrap: 'wrap' }}
                      >
                        <Button type='primary'>Upload</Button>
                      </Upload>
                    </Form.Item>

                    <MinusCircleOutlined
                      type='danger'
                      onClick={() => {
                        remove(name);
                        removeCorrigendum(index);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </Space>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                    addCorrigendum();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Corrigendum
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.List name='result'>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    marginBottom: 8
                  }}
                  direction='vertical'
                >
                  <Form.Item
                    {...restField}
                    label={`result ${index + 1}`}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    rules={[{ required: true, message: 'Missing result title' }]}
                    style={{ width: '100%' }}
                  >
                    <Input
                      placeholder='Result Title'
                      defaultValue={result[index]?.title} // Set default value
                      onChange={(e) => {
                        const newResult = [...result];
                        newResult[index].title = e.target.value;
                        setResult(newResult);
                      }}
                    />
                  </Form.Item>

                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'document']}
                      fieldKey={[fieldKey, 'document']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[{ required: true, message: 'Missing Notice file' }]}
                      style={{ flexGrow: 1 }}
                    >
                      <Upload
                        name='document'
                        listType='picture'
                        action={`${API_BASE_URL}/api/upload/`}
                        fileList={result[index]?.file}
                        onChange={handleResultChange(index)}
                        maxCount={1}
                        // style={{ textWrap: 'wrap' }}
                      >
                        <Button type='primary'>Upload</Button>
                      </Upload>
                    </Form.Item>

                    <MinusCircleOutlined
                      type='danger'
                      onClick={() => {
                        remove(name);
                        removeResult(index);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </Space>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                    addResult();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Result
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.Item
          name='advt_date'
          label={`Advertisement Date -[${appointment?.advt_date ? appointment?.advt_date : ''}]`}
          rules={[
            {
              type: 'object' as const,
              //   required: true,
              message: 'Please select Advertisement Date'
            }
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name={`advt_opendate`}
          label={` Advertisement Open Date  -[${
            appointment?.advt_opendate ? appointment?.advt_opendate : ''
          }]`}
          rules={[{ type: 'object' as const, message: 'Please select Advertisement!' }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name='advt_opentime'
          label={`Advertisement Open Time -[${
            appointment?.advt_opentime ? appointment?.advt_opentime : ''
          }]`}
          rules={[
            {
              type: 'object' as const,
              // required: true,
              message: 'Please select Seek Clarification Start Time!'
            }
          ]}
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          name='advt_closedate'
          label={`Advertisement  Close Date -[${
            appointment?.advt_closedate ? appointment?.advt_closedate : ''
          }]`}
          rules={[
            {
              type: 'object' as const,
              // required: true,
              message: 'Please select Seek Clarification Close Date!'
            }
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name='advt_closetime'
          label={`Advertisement Close Time - [${
            appointment?.advt_closetime ? appointment?.advt_closetime : ''
          }]`}
          rules={[
            {
              type: 'object' as const,
              // required: true,
              message: 'Please select Seek Clarification Close Time!'
            }
          ]}
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          name='status'
          label={`Advertisement Status- [${appointment?.status ? appointment?.status : ''}]`}
          rules={[{ message: 'Please select Advertisement Status!' }]}
        >
          <Select placeholder='select your Advertisement Status'>
            <Option value='Open'>Open</Option>
            <Option value='Closed'>Closed</Option>
            <Option value='Cancled'>Cancled</Option>
            <Option value='Suspend'>Suspend</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Advertisement Mode'
          name='advt_mode'
          rules={[{ message: 'Please enter your Advertisement mode' }]}
        >
          <Input
            placeholder='Advertisement Mode(OFFLINE/ONLINE)'
            defaultValue={appointment?.advt_mode}
          />
        </Form.Item>

        <Form.Item
          name='advtmode_link'
          label='Advertisement Link'
          rules={[{ message: 'Please enter Advertisement mode link' }]}
        >
          <Input placeholder='Advertisement Mode link' defaultValue={appointment?.advtmode_link} />
        </Form.Item>

        <Form.Item>
          <div className='d-flex justify-content-between buttons-list settings-actions'>
            <Button danger onClick={onCancel}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit'>
              {submitText}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdvertisementFormEdit;
