import React from 'react';
import { IAppointment } from '../../../interfaces/patient';
import AppointmentForm from './StudentnoticeForm';
import StudentnoticeEditForm from './StudentnoticeEditForm' 
import { Modal } from 'antd';

type Props = {
  onEdited: (IAppointment) => void;
  appointment: IAppointment;
  visible: boolean;
  onClose: () => void;
};

const EditAppointment = ({ appointment, visible, onClose, onEdited }: Props) => {
  return (
    // <Modal
    //   visible={visible}
    //   onCancel={onClose}
    //   destroyOnClose
    //   footer={null}
    //   title={<h3 className='title'>Edit  Notice</h3>}
    // >
    //   <AppointmentForm
    //     onCancel={onClose}
    //     onSubmit={onEdited}
    //     appointment={appointment}
    //     submitText='Edit  Notice'
    //   />
    // </Modal>
    <Modal
    visible={visible}
    onCancel={onClose}
    destroyOnClose
    footer={null}
    title={<h3 className='title'>Edit  Notice</h3>}
  >
    <StudentnoticeEditForm
      onCancel={onClose}
      onSubmit={onEdited}
      appointment={appointment}
      submitText='Edit  Notice'
    />
  </Modal>
  );
};

export default EditAppointment;
