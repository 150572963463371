import React from 'react';
import { Button, Input, Card, Form } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from '../../apiService/apiservices';
import PublicLayout from '../../layout/public/Public';
import { loginSuccess, setUser } from '../../redux/auth/action';
import { useDispatch } from 'react-redux';

const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
};

const SignIn = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const dispatch = useDispatch();

  const login = async (values: any) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, values, {
        withCredentials: true
      });

      if (response.status === 200) {
        dispatch(setUser(response.data.user));
        dispatch(loginSuccess());
        navigateHome();
      } else {
        alert('Failed to login. Please try again.');
      }
    } catch (error: unknown) {
      let errorMessage = 'Failed to login. Please try again.';
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 404:
            errorMessage = 'User not found. Please check your username.';
            break;
          case 401:
            errorMessage = 'Invalid credentials. Please check your password.';
            break;
          case 500:
            errorMessage = 'Internal server error. Please try again later.';
            break;
          default:
            errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } else if (error instanceof Error) {
        // The request was made but no response was received or some other error occurred
        errorMessage = error.message;
      }
      alert(errorMessage);
      console.error('Login error:', error);
    }
  };

  const onFinish = (values: any) => {
    login(values);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/aiimsbanner.jpg`}>
      <h4 className='mt-0 mb-1'>Admin Login</h4>
      <p className='text-color-200'>Login to access your Account</p>
      <Card>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name='username'
            rules={[{ required: true, message: 'Please input your username' }]}
          >
            <Input placeholder='username' />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password' }]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>
          <Form.Item>
            <div className='d-flex justify-content-between buttons-list settings-actions'>
              <Button type='primary' htmlType='submit' icon={<LoginOutlined />}>
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <br />
      {/* <p>
        Don't have an account? <Link to='sign-up'>Sign up!</Link>
      </p> */}
    </PublicLayout>
  );
};

export default SignIn;
