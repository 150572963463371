import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';
import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';
import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';
import { fetchUserDetails, initializeSession } from './redux/auth/action';
import { AppState } from './redux/store';

const Routes = ({ routes, layout = '' }) => {
  return (
    <Switch>
      {routes.map((route: any, index: any) => (
        <Route
          key={index}
          exact={route.exact}
          path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`}
          component={() => <route.component />}
        />
      ))}
      <Route path='*'>
        <Redirect to='/pages/sessions/404' />
      </Route>
    </Switch>
  );
};

const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;
const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: AppState) => state.auth);
  const [sessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    // Initialize session and fetch user details
    const checkSession = async () => {
      await dispatch(initializeSession);
      setSessionChecked(true);
    };

    checkSession();
  }, [dispatch]);

  useHideLoader();

  // Show a loading indicator or blank screen while session validation is in progress
  if (!sessionChecked) {
    return <div>Loading...</div>;
  }

  return (
    <Switch>
      <Route path='/' exact>
        <Redirect to={isAuthenticated ? '/vertical' : '/public/sign-in'} />
      </Route>
      <Route path='/public'>
        <SessionRoutes />
      </Route>
      <Route path='/horizontal'>
        <HorizontalLayout>
          {isAuthenticated ? (
            <DefaultRoutes layout='horizontal' />
          ) : (
            <Redirect to='/public/sign-in' />
          )}
        </HorizontalLayout>
      </Route>
      <Route path='/vertical'>
        <VerticalLayout>
          {isAuthenticated ? (
            <DefaultRoutes layout='vertical' />
          ) : (
            <Redirect to='/public/sign-in' />
          )}
        </VerticalLayout>
      </Route>
      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default App;
