import React, { useEffect, useState } from 'react';

import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload } from 'antd';
// import { Card, Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { API_BASE_URL } from '../../../apiService/apiservices';

import ImageLoader from '../../../layout/components/patients/ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';
import axios from 'axios';
import { IAppointment } from '../../../interfaces/patient';
const Option = Select.Option;
type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Add Appointment';
const emptyAppointment: IAppointment = {
  date: '',
  doctor: '',
  email: '',
  injury: '',
  name: '',
  number: '',
  from: '',
  fromTo: '',
  to: '',
  tender_number: '',
  id: '',
  tender_closetime: null,
  opening_date: null,
  Close_date: null,
  Remarks: '',
  prebid_closetime: null,
  prebid_closedate: null,
  prebid_time: null,
  prebid_date: null,
  img: ''
};

// Define the Yup schema using Moment objects for date, time, and datetime fields
const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required(),
  id: Yup.string().required(),
  tender_closetime: Yup.mixed().required(), // Allow Moment objects or null
  opening_date: Yup.mixed().required(), // Allow Moment objects or null
  Close_date: Yup.mixed().required(), // Allow Moment objects or null
  Remarks: Yup.string().required(),
  prebid_closetime: Yup.mixed().required(), // Allow Moment objects or null
  prebid_closedate: Yup.mixed().required(), // Allow Moment objects or null
  prebid_time: Yup.mixed().required(), // Allow Moment objects or null
  prebid_date: Yup.mixed().required() // Allow Moment objects or null
});

const AppointmentForm = ({
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel
}: Props) => {
  const {
    // handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm
  } = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      // console.log("Submit Sucess");
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}`, img });
      resetForm();
    }
  });
  // const [from, to] = values.fromTo.split('-');
  const [img, setImg] = useState(values.img);
  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  // useEffect(() => {
  //   setValues({ ...values, from, to });
  // }, [appointment]);

  const handleImageLoad = (image) => {
    setImg(image);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   props.form.validateFields((err, values) => {
  //     if (!err) {
  //       console.log('Received values of form: ', values);
  //     }
  //   });
  // };

  const hasError = hasErrorFactory(touched, errors);

  const onUpdate = async (fieldsValue: any) => {
    // console.log('fieldsValue', fieldsValue);
    const values = {
      ...fieldsValue,
      uploaddate:
        fieldsValue['uploaddate'] && fieldsValue['uploaddate']
          ? fieldsValue['uploaddate']?.format('YYYY-MM-DD')
          : '',
      notice_doc:
        fieldsValue['notice_doc'] && fieldsValue['notice_doc'][0].name
          ? fieldsValue['notice_doc'][0].name
          : ''
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/student_notice/update/${appointment?._id}`,
        values,
        {
          withCredentials: true
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert('Updated successfully!');
        onCancel();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e.fileList);

    return e.fileList;
  };

  return (
    <>
      <Card>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onUpdate}
        >
          <Form.Item
            name='notice_doc'
            label='Notice Document'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
              <Button>Click to upload</Button>
              {/* quotation_num */}
            </Upload>
          </Form.Item>
          <Form.Item
            name='Notice_title'
            rules={[
              {
                // required: true,
                message: 'Please input your result Number'
              }
            ]}
          >
            <Input placeholder='Notice title' defaultValue={appointment?.Notice_title} />
          </Form.Item>

          <Form.Item
            name='id'
            rules={[
              {
                // required: true,
                message: 'Please input your result ID'
              }
            ]}
          >
            <Input placeholder='Notice   ID' defaultValue={appointment?.id} />
          </Form.Item>

          <Form.Item
            name='uploaddate'
            label={`Upload Date hello -[${appointment?.uploaddate ? appointment?.uploaddate : ''}]`}
            rules={[
              {
                type: 'object' as const,
                // required: true,
                message: 'Please select Seek Clarification Start Date!'
              }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name='NoticeFor'
            label={`Notice For -[${appointment?.NoticeFor ? appointment?.NoticeFor : ''}]`}
            rules={[
              {
                //  required: true,
                message: 'Please select Notice  For!'
              }
            ]}
          >
            <Select placeholder='select your Notice  For'>
              <Option value='Msoffice'>Msoffice</Option>
              <Option value='DeanOffice'>DeanOffice</Option>
              <Option value='Faculty'>Faculty</Option>
              <Option value='Student'>Student</Option>
              <Option value='General'>General</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className='d-flex justify-content-between buttons-list settings-actions'>
              <Button danger onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit'>
                {submitText}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AppointmentForm;
