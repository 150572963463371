import React from 'react';
import { IAppointment } from '../../../interfaces/patient';
import AppointmentForm from './AdvertisementForm';
import { Modal } from 'antd';
import AdvertisementFormEdit from './AdvertisementFormEdit';

type Props = {
  onEdited: (IAppointment) => void;
  appointment: IAppointment;
  visible: boolean;
  onClose: () => void;
};

const EditAppointment = ({ appointment, visible, onClose, onEdited }: Props) => {
  // console.log('appointment', appointment)

  return (
    // <Modal
    //   visible={visible}
    //   onCancel={onClose}
    //   destroyOnClose
    //   footer={null}
    //   title={<h3 className='title'>Edit suraj Advertisement</h3>}
    // >
    //   <AppointmentForm
    //     onCancel={onClose}
    //     onSubmit={onEdited}
    //     appointment={appointment}
    //     submitText='Edit Advertisement'
    //   />
    // </Modal>

    <Modal
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={<h3 className='title'>Edit Advertisement</h3>}
    >
      <AdvertisementFormEdit
        onCancel={onClose}
        onSubmit={onEdited}
        appointment={appointment}
        submitText='Edit Advertisement'
      />
    </Modal>
  );
};

export default EditAppointment;
