import React, { ReactNode } from 'react';

import { Avatar, Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import { IAppointment } from '../../../interfaces/patient';

// const AppointmentImg = ({ img }) => {
//   const isData = img.startsWith('data:image');
//   const isWithPath = img.startsWith('http');

//   if (isData || isWithPath) {
//     return <Avatar size={40} src={img} />;
//   }

//   return <Avatar size={40} src={`${window.location.origin}/${img}`} />;
// };

const columns: ColumnProps<IAppointment>[] = [
  // {
  //   key: 'img',
  //   title: 'Tender Documnet',
  //   dataIndex: 'img',
  //   render: (img) => <AppointmentImg img={img} />
  // },
  {
    key: 'Research_Topic',
    dataIndex: 'Research_Topic',
    title: 'Research Title',
   
    render: (tender_number) => <strong>{tender_number}</strong>
  },
  
  // {
  //   key: 'email',
  //   dataIndex: 'email',
  //   title: 'Email',
  //   sorter: (a, b) => (a.email > b.email ? 1 : -1),
  //   render: (email) => (
  //     <span className='nowrap' style={{ color: '#336cfb' }}>
  //       <span className='icofont icofont-ui-email mr-1' style={{ fontSize: 16 }} />
  //       {email}
  //     </span>
  //   )
  // },
  {
    key: 'Meeting_Date',
    dataIndex: 'Meeting_Date',
    title: 'Meeting Date',
    render: (date) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {date}
      </span>
    )
  },
  // {
  //   key: 'close_date',
  //   dataIndex: 'close_date',
  //   title: 'Close Date',
  //   render: (date) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {date}
  //     </span>
  //   )
  // },
  // {
  //   key: 'visit',
  //   title: 'Visit time',
  //   render: (appointment) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {appointment.fromTo}
  //     </span>
  //   )
  // },
 
  // { key: 'doctor', title: 'Doctor', dataIndex: 'doctor' },
  // { key: 'condition', title: 'Injury/Condition', dataIndex: 'injury' },
  {}
];

type Props = {
  data: IAppointment[];
  actions?: (appointment: IAppointment) => ReactNode;
};

const AppointmentsTable = ({ data, actions }: Props) => {
  const actionColumn: ColumnProps<IAppointment> = {
    key: 'actions',
    title: 'Actions',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  return (
    <Table
      rowKey='number'
      dataSource={data}
      columns={displayedColumns}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default AppointmentsTable;
