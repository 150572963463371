import React from 'react';

import { Card } from 'antd';

import ReactEcharts from 'echarts-for-react';

import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
import hospitalOptions from './charts/hospital-options';

import { incomeInWeek, incomeInMonth } from './charts/income-options';

import {
  patientsGenderOptions,
  departmentsOptions,
  patientsAgeOptions
} from './charts/patients-options';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import { API_BASE_URL } from '../../../apiService/apiservices';

const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'default-dashboard'
    },
    {
      title: 'Default'
    }
  ]
};

const DashboardPage = () => {
  // const [appointments] = useFetchPageData<IAppointment[]>('./data/last-appointments.json', []);

  const [advertisementTable] = useFetchPageData<IAppointment[]>(
    // './data/last-appointments.json',
    // 'https://api.aiimspatna.edu.in/aiims_advertisement_table',
    `${API_BASE_URL}/aiims_advertisement_table`,
    []
  );

  const [tenderTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/aiims_tender_table',
    []
  );

  const [quotationTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/aiims_quotation_table',
    []
  );

  const [studentNoticeTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/student_notice',
    []
  );

  const [doctorsTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/doctor/',
    []
  );

  const [researchListTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/research_list',
    []
  );

  const [biomedicalListTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/biomedicals',
    []
  );

  const [marqueeListTable] = useFetchPageData<IAppointment[]>(
    //'./data/appointments.json',

    'https://api.aiimspatna.edu.in/marquee',
    []
  );




  usePageData(pageData);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/advertisement">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Advertisement</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                {advertisementTable?.length ? advertisementTable.length : '...'}
                </div>
              </div>
            </div>
          </Card>
		 </a>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/appointments">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Tender</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                {tenderTable?.length ? tenderTable.length : '...'}
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/quotation">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Quotation</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                {quotationTable?.length ? quotationTable.length : '...'}
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/studentnotice">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Student Notice</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {studentNoticeTable?.length ? studentNoticeTable.length : '...'}
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div>
      </div>

      <div className='row'>
        {/* <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/recruitmentnotice">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Rec. Notice</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  169
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div> */}

        {/* <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/recruitmentresult">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Rec. Result</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  139
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div> */}

        <div className='col-12 col-md-6 col-xl-3'>
        <a href='https://admin.aiimspatna.edu.in/#/vertical/doctor'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Doctors</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                {doctorsTable?.length ? doctorsTable.length : "..."}
                </div>
              </div>
            </div>
          </Card>
          </a>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
		<a href="https://admin.aiimspatna.edu.in/#/vertical/research">
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-square-right' 
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Research List</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                {researchListTable?.length ? researchListTable.length : "..."}
                </div>
              </div>
            </div>
          </Card>
		</a>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <a href='https://admin.aiimspatna.edu.in/#/vertical/biomedical'>
      
        
            <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
              <div className='row'>
                <div className='col-5'>
                  <span
                    className='icofont icofont-square-right'
                    style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                  />
                </div>

                <div className='col-7'>
                  <h6 className='mt-0 mb-1'>BMW Reports</h6>
                  <div
                    className='count'
                    style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}
                  >
                    {biomedicalListTable?.length ? biomedicalListTable.length : "..."}
                  </div>
                </div>
              </div>
            </Card>
          </a>
        </div>



        {/* Marquee from here */}

        <div className='col-12 col-md-6 col-xl-3'>
          <a href='https://admin.aiimspatna.edu.in/#/vertical/marquee'>
          
        
            <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
              <div className='row'>
                <div className='col-5'>
                  <span
                    className='icofont icofont-square-right'
                    style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                  />
                </div>

                <div className='col-7'>
                  <h6 className='mt-0 mb-1'>What's New</h6>
                  <div
                    className='count'
                    style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}
                  >
                    {marqueeListTable?.length ? marqueeListTable.length : "..."}
                  </div>
                </div>
              </div>
            </Card>
          </a>
        </div>




      </div>

      {/* <Card title='Hospital survey'>
        <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
      </Card>

      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <Card>
            <h4 className='mt-0 mb-1'>$25038</h4>
            <p className='mb-0' style={{ color: '#9d9d9d' }}>
              Income in current month
            </p>

            <ReactEcharts className='chart-container' option={incomeInMonth} />
          </Card>
        </div>

        <div className='col-sm-12 col-md-6'>
          <Card>
            <h4 className='mt-0 mb-1'>$2195</h4>
            <p className='mb-0' style={{ color: '#9d9d9d' }}>
              Income in current week
            </p>

            <ReactEcharts className='chart-container' option={incomeInWeek} />
          </Card>
        </div>
      </div> */}

      {/* <div className='row'>
        <div className='col-12 col-md-4'>
          <Card title={'patients age'}>
            <ReactEcharts className='chart-container container-h-300' option={patientsAgeOptions} />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'patients gender'}>
            <ReactEcharts
              className='chart-container container-h-300'
              option={patientsGenderOptions}
            />
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Departments'}>
            <ReactEcharts className='chart-container container-h-300' option={departmentsOptions} />
          </Card>
        </div>
      </div>

      <Card title='Last appointments' className='mb-0'>
        <AppointmentsTable data={appointments} />
      </Card> */}
    </>
  );
};

export default DashboardPage;
