import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload, Space } from 'antd';
// import { Card, Select } from 'antd';
// import { Field, useFormik } from 'formik';
import { useFormik } from 'formik';
import { API_BASE_URL } from '../../../apiService/apiservices';
import * as Yup from 'yup';
import {
  UploadOutlined,
  PlusOutlined,
  MinusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import ImageLoader from '../../../layout/components/patients/ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';
import axios from 'axios';
import { IAdvertisement } from '../../../interfaces/patient';
const Option = Select.Option;

type Props = {
  appointment?: IAdvertisement;
  onSubmit: (appointment: IAdvertisement) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Add Appointment';

const emptyAppointment = {
  date: '',
  doctor: '',
  email: '',
  fromTo: '',
  img: '',
  injury: '',
  name: '',
  number: '',
  from: '',
  to: '',
  tender_number: ''
};

const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required()
});

const AppointmentForm = ({
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel
}: Props) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm
  } = useFormik<IAdvertisement>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}`, img });
      resetForm();
    }
  });

  //commented code here fromTo is not defined in DB
  // const [from, to] = values.fromTo.split('-');
  const [advertisementDocument, setaAdvertisementDocument] = useState([]);

  const [corrigendums, setCorrigendums] = useState([]);

  const [result, setResult] = useState([]);

  const [img, setImg] = useState(values.img);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const formik = useFormik<IAdvertisement>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}`, img: '' });
      formik.resetForm();
    }
  });

  const handleTenderDocumentChange = ({ fileList }) => setaAdvertisementDocument(fileList);

  const handleCorrigendumChange =
    (index: number) =>
    ({ fileList }) => {
      const newCorrigendums = [...corrigendums];
      newCorrigendums[index].file = fileList;
      setCorrigendums(newCorrigendums);
    };

  //
  const addCorrigendum = () => {
    const newCorrigendums = [...corrigendums, {}]; // Add an empty object for new corrigendum
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const removeCorrigendum = (index: number) => {
    const newCorrigendums = corrigendums.filter((_, i) => i !== index);
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const normFile = (e: any) => (Array.isArray(e) ? e : e && e.fileList);

  useEffect(() => {
    formik.setFieldValue('corrigendums', corrigendums);
  }, [corrigendums]);
  //

  // for results
  const handleResultChange =
    (index: number) =>
    ({ fileList }) => {
      const newResult = [...result];
      newResult[index].file = fileList;
      setResult(newResult);
    };

  const addResult = () => {
    const newResult = [...result, {}]; // Add an empty object for new corrigendum
    setResult(newResult);
    formik.setFieldValue('newResult', newResult); // Update Formik's values
  };

  const removeResult = (index: number) => {
    const newResult = result.filter((_, i) => i !== index);
    setResult(newResult);
    formik.setFieldValue('result', setResult); // Update Formik's values
  };

  useEffect(() => {
    formik.setFieldValue('result', result);
  }, [result]);
  //  ---------------------------  end result

  //commented code here sy
  // useEffect(() => {
  //   setValues({ ...values, from, to });
  // }, [appointment]);

  const handleImageLoad = (image) => {
    setImg(image);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const hasError = hasErrorFactory(touched, errors);

  // const onFinish = (fieldsValue: any) => {
  //   console.log('fieldsValue', fieldsValue);

  //   // const advtNotices = formItems.map((item, index) => {
  //   //   const notice = fieldsValue[`advt_notice${index + 1}`];
  //   //   return notice && notice.length > 0 ? notice[0].name : null;
  //   // }).filter(Boolean);

  //   // try {
  //   //   console.log('from onFinish function');
  //   //   if (fieldsValue['advt_document'] != undefined) {
  //   //     const values = {
  //   //       ...fieldsValue,
  //   //       advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
  //   //       advt_opentime: fieldsValue['advt_opentime']
  //   //         ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
  //   //         : '',
  //   //       advt_closetime: fieldsValue['advt_closetime']
  //   //         ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
  //   //         : '',
  //   //       advt_opendate: fieldsValue['advt_opendate']
  //   //         ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
  //   //         : '',
  //   //       advt_closedate: fieldsValue['advt_closedate']
  //   //         ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
  //   //         : '',
  //   //       advt_document: fieldsValue['advt_document'][0].name,
  //   //       // 'addl_advt_doc' :fieldsValue['addl_advt_doc'][0].name,
  //   //       // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
  //   //       // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
  //   //       // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
  //   //       // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
  //   //       advt_notices: advtNotices,
  //   //     };
  //   //     axios.post(`${API_BASE_URL}/aiims_advertisement_table/add`, values);
  //   //     alert('submited');
  //   //     // console.log('Received values of form: ', values);
  //   //   } else {
  //   //     const values = {
  //   //       ...fieldsValue,
  //   //       advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
  //   //       advt_opentime: fieldsValue['advt_opentime']
  //   //         ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
  //   //         : '',
  //   //       advt_closetime: fieldsValue['advt_closetime']
  //   //         ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
  //   //         : '',
  //   //       advt_opendate: fieldsValue['advt_opendate']
  //   //         ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
  //   //         : '',
  //   //       advt_closedate: fieldsValue['advt_closedate']
  //   //         ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
  //   //         : '',
  //   //       advt_document: fieldsValue['advt_document'][0].name,
  //   //       addl_advt_doc: fieldsValue['addl_advt_doc'][0].name,
  //   //       // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
  //   //       // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
  //   //       // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
  //   //       // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
  //   //       advt_notices: advtNotices,
  //   //     };

  //   //     axios.post(`${API_BASE_URL}/aiims_advertisement_table/add`, values);
  //   //     alert('submited');

  //   //     // console.log('Received values of form: ', values);
  //   //   }
  //   // } catch (e) {
  //   //   const values = {
  //   //     ...fieldsValue,
  //   //     advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
  //   //     advt_opentime: fieldsValue['advt_opentime']
  //   //       ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
  //   //       : '',
  //   //     advt_closetime: fieldsValue['advt_closetime']
  //   //       ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
  //   //       : '',
  //   //     advt_opendate: fieldsValue['advt_opendate']
  //   //       ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
  //   //       : '',
  //   //     advt_closedate: fieldsValue['advt_closedate']
  //   //       ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
  //   //       : '',

  //   //     // 'advt_document' :fieldsValue['advt_document'][0].name,
  //   //     // 'addl_advt_doc' :fieldsValue['addl_advt_doc'][0].name,
  //   //     // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
  //   //     // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
  //   //     // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
  //   //     // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
  //   //     advt_notices: advtNotices,

  //   //   };
  //   //   axios.post(`${API_BASE_URL}/aiims_advertisement_table/add`, values);
  //   //   alert('submited');

  //   //   // console.log('Received values of form: ', values);
  //   // }
  // };

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
      advt_opentime: fieldsValue['advt_opentime']
        ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
        : '',
      advt_closetime: fieldsValue['advt_closetime']
        ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
        : '',
      advt_opendate: fieldsValue['advt_opendate']
        ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
        : '',
      advt_closedate: fieldsValue['advt_closedate']
        ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
        : '',
      advt_document: fieldsValue['advt_document'][0].name,

      advt_notices: corrigendums.map((item) => ({
        title: item.title,
        document: item.file[0]?.name
      })),
      advt_result: result.map((item) => ({
        title: item.title,
        document: item.file[0]?.name
      }))
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/aiims_advertisement_table/add`, values, {
        withCredentials: true
      });
      if (response.status === 200 || response.status === 201) {
        alert('Submitted successfully!');
        onCancel();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const onUpdate = (fieldsValue: any) => {
    try {
      // console.log('from onUpdate function');
      if (fieldsValue['advt_document'] != undefined) {
        const values = {
          ...fieldsValue,
          advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
          advt_opentime: fieldsValue['advt_opentime']
            ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
            : '',
          advt_closetime: fieldsValue['advt_closetime']
            ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
            : '',
          advt_opendate: fieldsValue['advt_opendate']
            ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
            : '',
          advt_closedate: fieldsValue['advt_closedate']
            ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
            : '',

          advt_document: fieldsValue['advt_document'][0].name
          // 'addl_advt_doc' :fieldsValue['addl_advt_doc'][0].name,
          // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
          // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
          // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
          // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
        };
        axios.post(`${API_BASE_URL}/aiims_advertisement_table/update/${appointment?._id}`, values, {
          withCredentials: true
        });
        alert('Updated');
        // console.log('Received values of form: ', values);
      } else {
        const values = {
          ...fieldsValue,
          advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
          advt_opentime: fieldsValue['advt_opentime']
            ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
            : '',
          advt_closetime: fieldsValue['advt_closetime']
            ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
            : '',
          advt_opendate: fieldsValue['advt_opendate']
            ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
            : '',
          advt_closedate: fieldsValue['advt_closedate']
            ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
            : '',
          advt_document: fieldsValue['advt_document'][0].name,
          addl_advt_doc: fieldsValue['addl_advt_doc'][0].name
          // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
          // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
          // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
          // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
        };
        axios.post(`${API_BASE_URL}/aiims_advertisement_table/update/${appointment?._id}`, values, {
          withCredentials: true
        });
        alert('Updated');

        // console.log('Received values of form: ', values);
      }
    } catch (e) {
      const values = {
        ...fieldsValue,
        advt_date: fieldsValue['advt_date'] ? fieldsValue['advt_date']?.format('YYYY-MM-DD') : '',
        advt_opentime: fieldsValue['advt_opentime']
          ? fieldsValue['advt_opentime']?.format('HH:mm:ss')
          : '',
        advt_closetime: fieldsValue['advt_closetime']
          ? fieldsValue['advt_closetime']?.format('HH:mm:ss')
          : '',
        advt_opendate: fieldsValue['advt_opendate']
          ? fieldsValue['advt_opendate']?.format('YYYY-MM-DD')
          : '',
        advt_closedate: fieldsValue['advt_closedate']
          ? fieldsValue['advt_closedate']?.format('YYYY-MM-DD')
          : ''

        // 'advt_document' :fieldsValue['advt_document'][0].name,
        // 'addl_advt_doc' :fieldsValue['addl_advt_doc'][0].name,
        // 'advt_notice1' :fieldsValue['advt_notice1'][0].name,
        // 'advt_notice2' :fieldsValue['advt_notice2'][0].name,
        // 'advt_notice3' :fieldsValue['advt_notice3'][0].name,
        // 'advt_notice4' :fieldsValue['advt_notice4'][0].name,
      };
      axios.post(`${API_BASE_URL}/aiims_advertisement_table/update/${appointment?._id}`, values, {
        withCredentials: true
      });
      alert('Updated');
    }
  };

  return (
    <>
      <Card>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={
            submitText == 'Add Advertisement'
              ? onFinish
              : submitText == 'Edit Advertisement'
              ? onUpdate
              : null
          }
        >
          <Form.Item
            label='Advertisement Number'
            name='advt_id'
            rules={[{ required: true, message: 'Please enter your Advertisement Number' }]}
          >
            <Input placeholder='Advertisement Number' />
          </Form.Item>
          <Form.Item
            label='Advertisement Name'
            name='advt_name'
            rules={[{ required: true, message: 'Please enter your Advertisement Name' }]}
          >
            <Input style={{ marginLeft: '15px' }} placeholder='Advertisement Name' />
          </Form.Item>

          <Form.Item
            name='advt_document'
            label='Advertisement Document'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Please upload Advertisement Document' }]}
            // extra="long"
          >
            <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name='addl_advt_doc'
            label='Additional Document'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
              <Button style={{ marginLeft: '3rem' }}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name='addl_advt_lebel'
            label='Additional Document Name'
            rules={[{ message: 'Please enter Additional Document Name' }]}
          >
            <Input placeholder='Additional Document Name' />
          </Form.Item>

          {/* <Form.Item
            name='advt_notice1'
            label='Advertisement Notice 1'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload
              name='tender_document'
              action={`${API_BASE_URL}/uploaddoc/`}
              listType='picture'
            >
              <Button>Click to upload</Button>
              <Button type='primary' className='ml-1'> + </Button>
              <Button type='primary' className='ml-1'> - </Button>

            </Upload>
            
          </Form.Item> */}
          {/* {formItems.map((item, index) => (
              <Form.Item
                key={item.id}
                name={`advt_notice${index + 1}`}
                label={`Advertisement Notice ${index + 1}`}
                valuePropName='fileList'
                getValueFromEvent={normFile}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Upload
                    name='tender_document'
                    action={`${API_BASE_URL}/uploaddoc/`}
                    listType='picture'
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                  <Button
                    type='primary'
                    className='ml-1'
                    icon={<PlusOutlined />}
                    onClick={addFormItem}
                  />
                  <Button
                    type='primary'
                    className='ml-1'
                    icon={<MinusOutlined />}
                    onClick={() => removeFormItem(item.id)}
                    disabled={formItems.length === 1}
                  />
                </div>
              </Form.Item>
            ))} */}
          <Form.List name='corrigendums'>
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      marginBottom: 8
                    }}
                    direction='vertical'
                  >
                    <Form.Item
                      {...restField}
                      label={`Notice ${index + 1}`}
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                      rules={[{ required: true, message: 'Missing notice title' }]}
                      style={{ width: '100%' }}
                    >
                      <Input
                        placeholder='Notice Title'
                        defaultValue={corrigendums[index]?.title} // Set default value
                        onChange={(e) => {
                          const newCorrigendums = [...corrigendums];
                          newCorrigendums[index].title = e.target.value;
                          setCorrigendums(newCorrigendums);
                        }}
                      />
                    </Form.Item>

                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'document']}
                        fieldKey={[fieldKey, 'document']}
                        valuePropName='fileList'
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: 'Missing Notice file' }]}
                        style={{ flexGrow: 1 }}
                      >
                        <Upload
                          name='document'
                          listType='picture'
                          action={`${API_BASE_URL}/api/upload/`}
                          fileList={corrigendums[index]?.file}
                          onChange={handleCorrigendumChange(index)}
                          maxCount={1}
                          // style={{ textWrap: 'wrap' }}
                        >
                          <Button type='primary'>Upload</Button>
                        </Upload>
                      </Form.Item>

                      <MinusCircleOutlined
                        type='danger'
                        onClick={() => {
                          remove(name);
                          removeCorrigendum(index);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </Space>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add();
                      addCorrigendum();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Notice
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>

          <Form.List name='result'>
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      marginBottom: 8
                    }}
                    direction='vertical'
                  >
                    <Form.Item
                      {...restField}
                      label={`result ${index + 1}`}
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                      rules={[{ required: true, message: 'Missing result title' }]}
                      style={{ width: '100%' }}
                    >
                      <Input
                        placeholder='Result Title'
                        defaultValue={result[index]?.title} // Set default value
                        onChange={(e) => {
                          const newResult = [...result];
                          newResult[index].title = e.target.value;
                          setResult(newResult);
                        }}
                      />
                    </Form.Item>

                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'document']}
                        fieldKey={[fieldKey, 'document']}
                        valuePropName='fileList'
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: 'Missing Notice file' }]}
                        style={{ flexGrow: 1 }}
                      >
                        <Upload
                          name='document'
                          listType='picture'
                          action={`${API_BASE_URL}/api/upload/`}
                          fileList={result[index]?.file}
                          onChange={handleResultChange(index)}
                          maxCount={1}
                          // style={{ textWrap: 'wrap' }}
                        >
                          <Button type='primary'>Upload</Button>
                        </Upload>
                      </Form.Item>

                      <MinusCircleOutlined
                        type='danger'
                        onClick={() => {
                          remove(name);
                          removeResult(index);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </Space>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add();
                      addResult();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Result
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>

          <Form.Item>
            {/* <Button type='primary' htmlType='submit'>
                Submit
              </Button> */}
          </Form.Item>

          {/* <Form.Item
            name='advt_notice2'
            label='Advertisement Notice 2'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload
              name='tender_document'
              action={`${API_BASE_URL}/uploaddoc/`}
              listType='picture'
            >
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name='advt_notice3'
            label='Advertisement Notice 3'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload
              name='tender_document'
              action={`${API_BASE_URL}/uploaddoc/`}
              listType='picture'
            >
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name='advt_notice4'
            label='Advertisement Notice 4'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            // extra="long"
          >
            <Upload
              name='tender_document'
              action={`${API_BASE_URL}/uploaddoc/`}
              listType='picture'
            >
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item> */}

          {/* <Form.Item
            name='advt_type'
            label='Advertisement Type'
            rules={[{ required: true, message: 'Please select Advertisement Type!' }]}
          >
            <Select placeholder='select your Advertisement Type'>
              <Option value='Closed Advertisement'>Closed Advertisement</Option>
              <Option value='Open Advertisement'>Open Advertisement</Option>
              <Option value='OPEN (RE)Advertisement'>OPEN (RE)Advertisement</Option>
            </Select>
          </Form.Item> */}

          {/* <Form.Item name="tender_closetime" label="Tender Closing Time" rules= {[{ type: 'object' as const, required: true, message: 'Please select Tender Closing Time!' }]}>
        <TimePicker />
      </Form.Item> */}

          <Form.Item
            name='advt_date'
            label=' Advertisement Date'
            rules={[
              {
                type: 'object' as const,
                required: true,
                message: 'Please select Advertisement Date'
              }
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name='advt_opendate'
            label=' Advertisement Open   Date'
            rules={[{ type: 'object' as const, message: 'Please select Advertisement!' }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name='advt_opentime'
            label='Advertisement Open Time'
            rules={[
              {
                type: 'object' as const,
                // required: true,
                message: 'Please select Seek Clarification Start Time!'
              }
            ]}
          >
            <TimePicker />
          </Form.Item>

          <Form.Item
            name='advt_closedate'
            label='Advertisement  Close Date'
            rules={[
              {
                type: 'object' as const,
                // required: true,
                message: 'Please select Seek Clarification Close Date!'
              }
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name='advt_closetime'
            label='Advertisement Close Time'
            rules={[
              {
                type: 'object' as const,
                // required: true,
                message: 'Please select Seek Clarification Close Time!'
              }
            ]}
          >
            <TimePicker />
          </Form.Item>

          <Form.Item
            name='status'
            label='Advertisement Status'
            rules={[{ message: 'Please select Advertisement Status!' }]}
          >
            <Select placeholder='select your Advertisement Status'>
              <Option value='Open'>Open</Option>
              <Option value='Closed'>Closed</Option>
              <Option value='Cancled'>Cancled</Option>
              <Option value='Suspend'>Suspend</Option>
            </Select>
          </Form.Item>

          {/* <Form.Item name="opening_date" label="Tender Opening  Date" rules= {[{ type: 'object' as const, required: true, message: 'Please select Tender Opening  Date!' }]}>
        <DatePicker />
      </Form.Item>

      <Form.Item name="Close_date" label="Tender Closing  Date" rules= {[{ type: 'object' as const, required: true, message: 'Please select Tender Closing  Date' }]}>
        <DatePicker />
      </Form.Item> */}

          <Form.Item
            label='Advertisement Mode'
            name='advt_mode'
            rules={[{ message: 'Please enter your Advertisement mode' }]}
          >
            <Input placeholder='Advertisement Mode(OFFLINE/ONLINE)' />
          </Form.Item>

          <Form.Item
            name='advtmode_link'
            label='Advertisement Link'
            rules={[{ message: 'Please enter Advertisement mode link' }]}
          >
            <Input placeholder='Advertisement Mode link' />
          </Form.Item>

          <Form.Item>
            {/* <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button> */}
            <div className='d-flex justify-content-between buttons-list settings-actions'>
              <Button danger onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit'>
                {submitText}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AppointmentForm;
