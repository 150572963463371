import axios from 'axios';
import cryptoJS from 'crypto-js';
import { API_BASE_URL } from '../../apiService/apiservices';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

const secretKey = '49720f6edc5c2840fb376e3c2b205cdf85d54%4%6458010f7f3edb7f59cfe073f7';

// Action creators
export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const userDetailsLoading = (status) => ({
  type: USER_DETAILS_LOADING,
  payload: status,
});

export const userDetailsError = (error) => ({
  type: USER_DETAILS_ERROR,
  payload: error,
});

// Function to encode and set user data in a cookie
const setEncodedUserDataInCookie = (session, secretKey) => {
  try {
    if (session) {
      const userDataJSON = JSON.stringify(session);
      const encryptedData = cryptoJS.AES.encrypt(userDataJSON, secretKey).toString();
      const expirationInSeconds = 3600; // 1 hour
      const expirationDate = new Date(Date.now() + expirationInSeconds * 1000).toUTCString();

      document.cookie = `sid_enco_data_ga=${encodeURIComponent(encryptedData)}; expires=${expirationDate}; SameSite=Lax; path=/`;
    }
  } catch (error) {
    console.error('Error encoding user data:', error);
  }
};

// Function to decode user data from a cookie
const getDecodedUserDataFromCookie = (secretKey) => {
  try {
    const cookies = document.cookie.split(';');
    const encodedUserDataCookie = cookies.find((cookie) => cookie.trim().startsWith('sid_enco_data_ga='));

    if (encodedUserDataCookie) {
      const encodedUserDataString = encodedUserDataCookie.split('=')[1];
      const decodedEncodedUserData = decodeURIComponent(encodedUserDataString);
      const decryptedData = cryptoJS.AES.decrypt(decodedEncodedUserData, secretKey).toString(cryptoJS.enc.Utf8);

      return JSON.parse(decryptedData);
    }
  } catch (error) {
    console.error('Error decoding user data:', error);
  }

  return null;
};

// Function to delete a cookie
export const deleteCookie = (cookieName, path = '/') => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; SameSite=Lax`;
  // console.log(`Cookie ${cookieName} deleted.`);
};

// Function to get user from cookie
const getUserFromCookie = () => {
  return getDecodedUserDataFromCookie(secretKey);
};

// Async function to fetch user details
export const fetchUserDetails = () => async (dispatch) => {
  dispatch(userDetailsLoading(true));

  try {
    const response = await axios.get(`${API_BASE_URL}/auth/user/me`, { withCredentials: true });

    if (response.data) {
      dispatch(setUser(response.data));
      setEncodedUserDataInCookie(response.data, secretKey);
      dispatch(loginSuccess());
    }
  } catch (error) {
    console.error('Failed to fetch user details:', error);
    dispatch(userDetailsError(error));
  } finally {
    dispatch(userDetailsLoading(false));
  }
};

// Function to check if user is already logged in
const checkUserSession = (dispatch) => {
  const user = getUserFromCookie();

  if (user) {
    dispatch(setUser(user));
    dispatch(loginSuccess());
  } else {
    dispatch(logout());
  }
};

// Call this function on app startup
export const initializeSession = (dispatch) => {
  checkUserSession(dispatch);
};

// Action to log out user
export const userLogout = () => (dispatch) => {
  deleteCookie('sid_enco_data_ga');
  dispatch(logout());
};

