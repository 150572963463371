import React, { ReactNode } from 'react';

import { Avatar, Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import { IAppointment } from '../../../interfaces/patient';

// const AppointmentImg = ({ img }) => {
//   const isData = img.startsWith('data:image');
//   const isWithPath = img.startsWith('http');

//   if (isData || isWithPath) {
//     return <Avatar size={40} src={img} />;
//   }

//   return <Avatar size={40} src={`${window.location.origin}/${img}`} />;
// };



function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;

  return [year, month, day].join('-');
}



const columns: ColumnProps<IAppointment>[] = [
  // {
  //   key: 'img',
  //   title: 'Tender Documnet',
  //   dataIndex: 'img',
  //   render: (img) => <AppointmentImg img={img} />
  // },
  {
    key: 'tender_description',
    dataIndex: 'tender_description',
    title: 'Tender Name',
   
    render: (tender_description) => <strong>{tender_description}</strong>
  },
  {
    key: 'tender_number',
    dataIndex: 'tender_number',
    title: 'Tender Number',
   
    render: (tender_number) => <strong>{tender_number}</strong>
  },
  {
    key: 'Close_date',
    dataIndex: 'Close_date',
    title: 'Closing Date',
   
    render: (Close_date) => <strong>{formatDate(Close_date)}</strong>
  },


  // {
  //   key: 'name',
  //   dataIndex: 'name',
  //   title: 'Tender Type',
  //   sorter: (a, b) => (a.name > b.name ? 1 : -1),
  //   render: (name) => <strong>{name}</strong>
  // },
  // {
  //   key: 'email',
  //   dataIndex: 'email',
  //   title: 'Email',
  //   sorter: (a, b) => (a.email > b.email ? 1 : -1),
  //   render: (email) => (
  //     <span className='nowrap' style={{ color: '#336cfb' }}>
  //       <span className='icofont icofont-ui-email mr-1' style={{ fontSize: 16 }} />
  //       {email}
  //     </span>
  //   )
  // },
  // {
  //   key: 'data',
  //   dataIndex: 'date',
  //   title: 'Open Date',
  //   render: (date) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {date}
  //     </span>
  //   )
  // },
  // {
  //   key: 'data',
  //   dataIndex: 'date',
  //   title: 'Close Date',
  //   render: (date) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {date}
  //     </span>
  //   )
  // },
  // {
  //   key: 'visit',
  //   title: 'Visit time',
  //   render: (appointment) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {appointment.fromTo}
  //     </span>
  //   )
  // },
  // {
  //   key: 'number',
  //   dataIndex: 'number',
  //   title: 'Tender Number',
  //   render: (phone) => (
  //     <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
  //       <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
  //       {phone}
  //     </span>
  //   )
  // },
  // { key: 'doctor', title: 'Doctor', dataIndex: 'doctor' },
  // { key: 'condition', title: 'Injury/Condition', dataIndex: 'injury' },
  {}
];

type Props = {
  data: IAppointment[];
  actions?: (appointment: IAppointment) => ReactNode;
};

const AppointmentsTable = ({ data, actions }: Props) => {
  const actionColumn: ColumnProps<IAppointment> = {
    key: 'actions',
    title: 'Actions',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  return (
    <Table
      rowKey='number'
      dataSource={data}
      columns={displayedColumns}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default AppointmentsTable;
