import React, { useEffect, useState } from 'react';

import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload } from 'antd';
// import { Card, Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ImageLoader from '../../../layout/components/patients/ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';
import axios from 'axios';
import { IAppointment } from '../../../interfaces/patient';
import { API_BASE_URL } from '../../../apiService/apiservices';
const Option = Select.Option;
type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Add Appointment';
const emptyAppointment: IAppointment = {
  date: '',
  doctor: '',
  email: '',
  injury: '',
  name: '',
  number: '',
  from: '',
  fromTo: '',
  to: '',
  tender_number: '',
  id: '',
  tender_closetime: null,
  opening_date: null,
  Close_date: null,
  Remarks: '',
  prebid_closetime: null,
  prebid_closedate: null,
  prebid_time: null,
  prebid_date: null,
  img: ''
};

// Define the Yup schema using Moment objects for date, time, and datetime fields
const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required(),
  id: Yup.string().required(),
  tender_closetime: Yup.mixed().required(), // Allow Moment objects or null
  opening_date: Yup.mixed().required(), // Allow Moment objects or null
  Close_date: Yup.mixed().required(), // Allow Moment objects or null
  Remarks: Yup.string().required(),
  prebid_closetime: Yup.mixed().required(), // Allow Moment objects or null
  prebid_closedate: Yup.mixed().required(), // Allow Moment objects or null
  prebid_time: Yup.mixed().required(), // Allow Moment objects or null
  prebid_date: Yup.mixed().required() // Allow Moment objects or null
});

const AppointmentForm = ({
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel
}: Props) => {
  const {
    // handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm
  } = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      // console.log('jj');
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}`, img });
      resetForm();
    }
  });
  //   const [from, to] = values.fromTo.split('-');
  const [img, setImg] = useState(values.img);
  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  //   useEffect(() => {
  //     setValues({ ...values, from, to });
  //   }, [appointment]);

  const handleImageLoad = (image) => {
    setImg(image);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   props.form.validateFields((err, values) => {
  //     if (!err) {
  //       console.log('Received values of form: ', values);
  //     }
  //   });
  // };

  const hasError = hasErrorFactory(touched, errors);

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      Publishdate:
        fieldsValue['Publishdate'] && fieldsValue['Publishdate']
          ? fieldsValue['Publishdate']?.format('YYYY-MM-DD')
          : '',
      Advt_noticedoc:
        fieldsValue['Advt_noticedoc'] && fieldsValue['Advt_noticedoc'][0].name
          ? fieldsValue['Advt_noticedoc'][0].name
          : '',
      employee_code: ''
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/biomedicals/update/${appointment?._id}`,
        values,
        {
          withCredentials: true
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert('Updated successfully!');
        onCancel();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e.fileList);

    return e.fileList;
  };

  return (
    <>
      <Card>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label='BMW Reports Name'
            name='notice_title'
            rules={[
              {
                // required: true,
                message: 'Please fill Bio-Medical Waste Reports name'
              }
            ]}
          >
            <Input placeholder='BMW Reports Name' defaultValue={appointment?.name} />
          </Form.Item>

          <Form.Item
            name='Advt_noticedoc'
            label='BMW Documents'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Please upload Bio-Medical Waste Reports' }]}
            // extra="long"
          >
            <Upload name='tender_document' action={`${API_BASE_URL}/uploaddoc/`} listType='picture'>
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item>

          {/* <Form.Item
        name="advrt_no"
        rules={[{ required: true, message: 'Please input your Quotation Number' }]}
      >
        <Input  placeholder="Recruitment Notice Number" />
      </Form.Item>
      

      <Form.Item
        name="id"
        rules={[{ required: true, message: 'Please input your Tender ID' }]}
      >
        <Input  placeholder="Recruitment Notice  ID" />
      </Form.Item>
    
      
      

      <Form.Item name="Publishdate" label="Publish Date" rules= {[{ type: 'object' as const, required: true, message: 'Please select Seek Clarification Start Date!' }]}>
        <DatePicker />
      </Form.Item>
       */}

          <Form.Item>
            {/* <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button> */}
            <div className='d-flex justify-content-between buttons-list settings-actions'>
              <Button danger onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit'>
                {submitText}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      {/* <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <ImageLoader onLoad={handleImageLoad} src={img} />
        </div>

        <div className='form-group'>
          <Input
            name='tender_number'
            placeholder='Tender Number'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.tender_number}
            className={hasError('tender_number')}
          />
        </div>
        <div className='form-group'>
          <Input
            name='id'
            placeholder='Tender ID'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.id}
            className={hasError('id')}
          />
        </div>
        <div className='form-group'>
            <Select
              allowClear
              defaultValue='Open Tender'
              
              className='mb-lg-0 mb-4'
              placeholder='Select a person'
              optionFilterProp='children'
              filterOption={optionFilter}
            >
              
              <Option value='Open Tender'>Open Tender</Option>

              <Option value='Closed Tender'>Closed Tender</Option>
              <Option value='Limited Tender'>Limited Tender</Option>
              <Option value='OPEN (RE)Tender'>OPEN (RE)Tender</Option>
            </Select>
          </div>

         

        <div className='form-group'>
          <Input
            defaultValue={values.tender_closetime}
            placeholder='Tender Closing Time'
            onBlur={handleBlur}
            name='tender_closetime'
            onChange={handleChange}
            className={hasError('tender_closetime')}
          />
        </div>

       
        {/* <div className='form-group'>
          <Input
            defaultValue={values.prebid_date}
            placeholder='Seek Clarification Start Date'
            name='prebid_date'
            onChange={handleChange}
            onBlur={handleBlur}
            className={hasError('prebid_date')}
          />
        </div>
        <div className='form-group'>
          <Input
            defaultValue={values.prebid_time}
            placeholder='Seek Clarification Start Time'
            name='prebid_time'
            onChange={handleChange}
            onBlur={handleBlur}
            className={hasError('prebid_time')}
          />
        </div>
        <div className='form-group'>
          <Input
            defaultValue={values.prebid_closedate}
            placeholder='Seek Clarification Close Date'
            name='prebid_closedate'
            onChange={handleChange}
            onBlur={handleBlur}
            className={hasError('prebid_closedate')}
          />
        </div>
        <div className='form-group'>
          <Input
            defaultValue={values.prebid_closetime}
            placeholder='Seek Clarification Close Time'
            name='prebid_closetime'
            onChange={handleChange}
            onBlur={handleBlur}
            className={hasError('prebid_closetime')}
          />
        </div>
        <div className='form-group'>
          <Input
            defaultValue={values.Remarks}
            placeholder='Remarks'
            name='Remarks'
            onChange={handleChange}
            onBlur={handleBlur}
            className={hasError('Remarks')}
          />
        </div>
        <div className='form-group'>
            <Select
              allowClear
              defaultValue='lucy'
              className='mb-lg-0 mb-4'
              placeholder='Select a person'
              optionFilterProp='children'
              filterOption={optionFilter}
            >
              
              <Option value='lucy'>Select Status</Option>

              <Option value='Open'>Open</Option>
              <Option value='Closed'>Closed</Option>
              <Option value='Cancled'>Cancled</Option>
              <Option value='Suspend'>Suspend</Option>
            </Select>
          </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6'>
            <div className='form-group'>
              <Input
                name='opening_date'
                placeholder='Tender Opening Date'
                defaultValue={values.opening_date}
                onChange={handleChange}
                onBlur={handleBlur}
                className={hasError('opening_date')}
              />
            </div>
          </div>

          <div className='col-sm-12 col-md-6'>
            <div className='form-group'>
              <Input
                name='Close_date'
                placeholder='Tender Closing Date'
                defaultValue={values.Close_date}
                onChange={handleChange}
                onBlur={handleBlur}
                className={hasError('Close_date')}
              />
            </div>
          </div>
        
        </div> */}

      {/* <div className='form-group'>
          <Input
            type='phone'
            name='number'
            onBlur={handleBlur}
            placeholder='Number'
            onChange={handleChange}
            defaultValue={values.number}
            className={hasError('number')}
          />
        </div>

        <div className='form-group'>
          <Input
            name='injury'
            placeholder='Injury'
            onChange={handleChange}
            defaultValue={values.injury}
            onBlur={handleBlur}
            className={hasError('injury')}
          />
        </div> */}

      {/* <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button> */}
      {/* <Button  type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>  */}
    </>
  );
};

export default AppointmentForm;
