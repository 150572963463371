import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload, Space } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { IAppointment } from '../../../interfaces/patient';
import { API_BASE_URL } from '../../../apiService/apiservices';

const { Option } = Select;

type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Add Appointment';

const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required(),
  id: Yup.string().required(),
  tender_closetime: Yup.string().required(),
  opening_date: Yup.string().required(),
  Close_date: Yup.string().required(),
  Remarks: Yup.string().required(),
  prebid_closetime: Yup.string().required(),
  prebid_closedate: Yup.string().required(),
  prebid_time: Yup.string().required(),
  prebid_date: Yup.string().required()
});

const TenderEditForm = ({
  submitText = defaultSubmitText,
  appointment,
  onSubmit,
  onCancel
}: Props) => {
  const [tenderDocument, setTenderDocument] = useState([]);
  const [corrigendums, setCorrigendums] = useState([]);
  const [preTenderDocument, setPreTenderDocument] = useState(
    appointment?.tender_document ? appointment.tender_document : ''
  );
  const [preCorrigendums, setPreCorrigendums] = useState(
    appointment?.corrigendums ? appointment.corrigendums : []
  );

  // console.log(preTenderDocument, 'tenderDocument data');
  // console.log(preCorrigendums, 'corrigendums data');
  const formik = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: {
      ...appointment,
      prebid_date: appointment ? moment(appointment.prebid_date) : null,
      prebid_closedate: appointment ? moment(appointment.prebid_closedate) : null,
      opening_date: appointment ? moment(appointment.opening_date) : null,
      Close_date: appointment ? moment(appointment.Close_date) : null
    },
    onSubmit: (form) => {
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}` });
      formik.resetForm();
    }
  });

  const handleTenderDocumentChange = ({ fileList }) => setTenderDocument(fileList);

  const handleCorrigendumChange =
    (index: number) =>
    ({ fileList }) => {
      const newCorrigendums = [...corrigendums];
      newCorrigendums[index].file = fileList;
      setCorrigendums(newCorrigendums);
    };

  const addCorrigendum = () => {
    const newCorrigendums = [...corrigendums, {}];
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums);
  };

  const removeCorrigendum = (index: number) => {
    const newCorrigendums = corrigendums.filter((_, i) => i !== index);
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums);
  };

  const normFile = (e: any) => (Array.isArray(e) ? e : e && e.fileList);

  useEffect(() => {
    formik.setFieldValue('corrigendums', corrigendums);
  }, [corrigendums]);

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      prebid_date: fieldsValue['prebid_date'].format('YYYY-MM-DD'),
      tender_closetime: fieldsValue['tender_closetime'].format('HH:mm:ss'),
      prebid_time: fieldsValue['prebid_time'].format('HH:mm:ss'),
      prebid_closetime: fieldsValue['prebid_closetime'].format('HH:mm:ss'),
      prebid_closedate: fieldsValue['prebid_closedate'].format('YYYY-MM-DD'),
      opening_date: fieldsValue['opening_date'].format('YYYY-MM-DD'),
      Close_date: fieldsValue['Close_date'].format('YYYY-MM-DD'),
      tender_document: tenderDocument[0]?.name,
      corrigendums: corrigendums.map((item) => ({
        title: item.title,
        document: item.file[0]?.name
      }))
    };

    // console.log(values, 'VALUES');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/aiims_tender_table/update/${appointment?._id}`,
        values,
        {
          withCredentials: true
        }
      );

      if (response.status === 200 || 201) {
        alert('Tender Updated successfully!');
        onCancel();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  // console.log(appointment, 'APPOINTMNT');

  return (
    <Card>
      <Form
        name='basic'
        className='login-form'
        autoComplete='off'
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          tender_number: appointment?.tender_number,
          tender_type: appointment?.tender_type,
          prebid_date: moment(appointment?.prebid_date),
          prebid_time: moment(appointment?.prebid_time, 'HH:mm:ss'),
          prebid_closedate: moment(appointment?.prebid_closedate),
          prebid_closetime: moment(appointment?.prebid_closetime, 'HH:mm:ss'),
          status: appointment?.status,
          opening_date: moment(appointment?.opening_date),
          Close_date: moment(appointment?.Close_date),
          tender_closetime: moment(appointment?.tender_closetime, 'HH:mm:ss'),
          tender_description: appointment?.tender_description,
          Remarks: appointment?.Remarks
        }}
      >
        <Form.Item
          label='Tender Number'
          name='tender_number'
          rules={[{ required: true, message: 'Please input your Tender Number' }]}
        >
          <Input placeholder='Tender Number' />
        </Form.Item>

        <Form.Item
          name='tender_type'
          label='Tender Type'
          rules={[{ required: true, message: 'Please select Tender Type!' }]}
        >
          <Select placeholder='select your Tender Type'>
            <Option value='Closed Tender'>Closed Tender</Option>
            <Option value='Open Tender'>Open Tender</Option>
            <Option value='OPEN (RE)Tender'>OPEN (RE)Tender</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='prebid_date'
          label='Pre-Bid Start Date'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Start Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name='prebid_time'
          label='Pre-Bid Start Time'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Start Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='prebid_closedate'
          label='Pre-Bid Close Date'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Close Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='prebid_closetime'
          label='Pre-Bid Close Time'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Close Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='status'
          label='Tender Status'
          rules={[{ required: true, message: 'Please input your Tender Status!' }]}
        >
          <Select placeholder='select your Tender Status'>
            <Option value='Pending'>Pending</Option>
            <Option value='Open'>Open</Option>
            <Option value='Closed'>Closed</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='opening_date'
          label='Opening Date'
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please input your Opening Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='Close_date'
          label='Closing Date'
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please input your Closing Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='tender_closetime'
          label='Tender Closing Time'
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please input your Tender Closing Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label='Tender Description'
          name='tender_description'
          rules={[{ required: true, message: 'Please input your Tender Description' }]}
        >
          <Input placeholder='Tender Description' />
        </Form.Item>
        <Form.Item
          label='Remarks'
          name='Remarks'
          rules={[{ required: true, message: 'Please input your Remarks' }]}
        >
          <Input placeholder='Remarks' />
        </Form.Item>

        {preTenderDocument !== '' && preTenderDocument !== undefined ? (
          <Form.Item name='tender_document' label='Uploaded Tender Document'>
            <a href={`${API_BASE_URL}/uploads/${preTenderDocument}`}>{preTenderDocument}</a>
          </Form.Item>
        ) : (
          ''
        )}

        <Form.Item
          label='Tender Document'
          name='tender_document'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          // rules={[{ required: true, message: 'Please upload Tender Document' }]}
        >
          <Upload.Dragger
            name='tender_document'
            listType='picture'
            action={`${API_BASE_URL}/uploaddoc/`}
            fileList={tenderDocument}
            onChange={handleTenderDocumentChange}
            maxCount={1}
          >
            {tenderDocument.length === 0 && (
              <>
                <p className='ant-upload-drag-icon'>
                  <i className='icofont icofont-file-pdf' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Support for a single upload.</p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>

        {preCorrigendums !== null && preCorrigendums !== undefined
          ? preCorrigendums.length > 0 &&
            preCorrigendums.map((elem, index) => (
              <Form.Item name='document' label='Uploaded Corrigendum'>
                <a href={`${API_BASE_URL}/uploads/${elem.document}`}>{elem.document}</a>
              </Form.Item>
            ))
          : ''}

        <Form.List name='corrigendums'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.key} align='baseline'>
                  <Form.Item
                    {...field}
                    label={`Corrigendum Title ${index + 1}`}
                    name={[field.name, 'title']}
                    fieldKey={[field.fieldKey, 'title']}
                    // rules={[{ required: true, message: 'Missing Corrigendum title' }]}
                  >
                    <Input
                      placeholder='Corrigendum Title'
                      defaultValue={corrigendums[index]?.title} // Set default value
                      onChange={(e) => {
                        const newCorrigendums = [...corrigendums];
                        // console.log(e, 'EVE');
                        // console.log(e.target, 'TARGETTTT');
                        newCorrigendums[index].title = e.target.value;
                        setCorrigendums(newCorrigendums);
                      }}
                    />
                  </Form.Item>

                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Form.Item
                      {...field}
                      label='Corrigendum Document'
                      name={[field.name, 'document']}
                      fieldKey={[field.fieldKey, 'document']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      extra='Upload corrigendum document'
                      // rules={[{ required: true, message: 'Missing Corrigendum document' }]}
                    >
                      <Upload
                        name='document'
                        listType='picture'
                        action={`${API_BASE_URL}/api/upload/`}
                        fileList={corrigendums[index]?.file}
                        onChange={handleCorrigendumChange(index)}
                        maxCount={1}
                        // style={{ textWrap: 'wrap' }}
                      >
                        <Button type='primary'>Upload</Button>
                      </Upload>
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        removeCorrigendum(index);
                      }}
                    />
                  </Space>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                    addCorrigendum();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Corrigendum
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {submitText}
          </Button>
          <Button type='default' onClick={onCancel} style={{ marginLeft: '10px' }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TenderEditForm;
