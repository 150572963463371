import React from 'react';
import { IAppointment } from '../../../interfaces/patient';
import AppointmentForm from './BioMedicalForm';
import { Modal } from 'antd';
import BioMedicalEditForm from './BioMedicalEditForm'

type Props = {
  onEdited: (IAppointment) => void;
  appointment: IAppointment;
  visible: boolean;
  onClose: () => void;
};

const EditAppointment = ({ appointment, visible, onClose, onEdited }: Props) => {
  return (
    // <Modal
    //   visible={visible}
    //   onCancel={onClose}
    //   destroyOnClose
    //   footer={null}
    //   title={<h3 className='title'>Edit Bio-Medical Waste Reports</h3>}
    // >
    //   <AppointmentForm
    //     onCancel={onClose}
    //     onSubmit={onEdited}
    //     appointment={appointment}
    //     submitText='Edit Reports'
    //   />
    // </Modal>

    <Modal
    visible={visible}
    onCancel={onClose}
    destroyOnClose
    footer={null}
    title={<h3 className='title'>Edit Bio-Medical Waste Reports</h3>}
  >
    <BioMedicalEditForm
      onCancel={onClose}
      onSubmit={onEdited}
      appointment={appointment}
      submitText='Edit Reports'
    />
  </Modal>
  );
};

export default EditAppointment;
