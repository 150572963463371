import {
  LOGIN_SUCCESS,
  LOGOUT,
  SET_USER,
  USER_DETAILS_LOADING,
  USER_DETAILS_ERROR
} from './action';

interface AuthState {
  isAuthenticated: boolean;
  user: {
    username: string;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false, // Ensure loading is reset on logout
        error: null // Ensure error is cleared on logout
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false, // Set loading to false on successful user fetch
        error: null // Clear error on successful user fetch
      };
    case USER_DETAILS_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null // Clear error when loading starts
      };
    case USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false, // Set loading to false on error
        error: action.payload // Set error message
      };
    default:
      return state;
  }
};
