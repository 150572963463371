import React, { useState } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import EditAppointment from './EditAppointment';
import AddAppointment from './AddAppointment';
import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
import PageAction from '../../../layout/components/page-action/PageAction';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';
import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import { API_BASE_URL } from '../../../apiService/apiservices';

const pageData: IPageData = {
  title: 'Tender',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'AIIMS',
      route: 'default-dashboard'
    },
    {
      title: 'Tender'
    }
  ]
};

const AppointmentsPage = () => {
  usePageData(pageData);
  const [appointments, setAppointments] = useFetchPageData<IAppointment[]>(
    `${API_BASE_URL}/aiims_tender_table`,
    []
  );

  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment | null>(null);
  const [addingModalVisibility, setAddingModalVisibility] = useState(false);

  const handleDelete = async (appointment: IAppointment) => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/aiims_tender_table/delete/${appointment?._id}`,
        {},
        {
          withCredentials: true
        }
      );

      if (response.status === 200) {
        const newAppointments = appointments.filter((el) => el._id !== appointment._id);
        setAppointments(newAppointments);
        alert('Tender deleted successfully');
      } else {
        alert('Failed to delete the tender. Please try again.');
      }
    } catch (err) {
      console.error(err);
    }
  };

  // const handleDelete = (appointment: IAppointment) => {
  //   const newAppointments = appointments.filter((el) => el !== appointment);
  //   setAppointments(newAppointments);
  // };

  const handleEdit = (appointment: IAppointment) => {
    const editedAppointments = appointments.map((el) =>
      el !== selectedAppointment ? el : appointment
    );
    setAppointments(editedAppointments);
    setSelectedAppointment(null);
  };

  const openAddingModal = () => setAddingModalVisibility(true);

  const addAppointment = async (appointment: IAppointment) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/aiims_tender_table/add`, appointment, {
        withCredentials: true
      });
      // console.log('Response:', response);
      setAppointments([appointment, ...appointments]);
      setAddingModalVisibility(false);
      alert('Submitted successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  const closeAddingModal = () => setAddingModalVisibility(false);

  const openEditModal = (appointment: IAppointment) => setSelectedAppointment(appointment);

  const closeModal = () => {
    setSelectedAppointment(null);
  };

  const appointmentsActions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button onClick={() => openEditModal(appointment)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={() => handleDelete(appointment)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  return (
    <>
      <AppointmentsTable data={appointments} actions={appointmentsActions} />

      <PageAction onClick={openAddingModal} icon='icofont-stethoscope-alt' type='primary' />

      <AddAppointment
        onClose={closeAddingModal}
        visible={addingModalVisibility}
        onSubmit={addAppointment}
      />

      <EditAppointment
        appointment={selectedAppointment}
        visible={!!selectedAppointment}
        onClose={closeModal}
        onEdited={handleEdit}
      />
    </>
  );
};

export default AppointmentsPage;
