import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Card, Form, TimePicker, DatePicker, Upload, Space } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
// import { IAppointment } from '../../../interfaces/patient';
import { API_BASE_URL } from '../../../apiService/apiservices';
import moment, { Moment } from 'moment';

const { Option } = Select;

interface IAppointment {
  date: string;
  doctor: string;
  email: string;
  injury: string;
  name: string;
  number: string;
  from: string;
  to: string;
  tender_number: string;
  id: string;
  tender_closetime: Moment;
  opening_date: Moment;
  Close_date: Moment;
  Remarks: string;
  prebid_closetime: Moment;
  prebid_closedate: Moment;
  prebid_time: Moment;
  prebid_date: Moment;
  fromTo: string;
  img: string;
}

type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Add Appointment';
const emptyAppointment: IAppointment = {
  date: '',
  doctor: '',
  email: '',
  injury: '',
  name: '',
  number: '',
  from: '',
  fromTo: '',
  to: '',
  tender_number: '',
  id: '',
  tender_closetime: null,
  opening_date: null,
  Close_date: null,
  Remarks: '',
  prebid_closetime: null,
  prebid_closedate: null,
  prebid_time: null,
  prebid_date: null,
  img: ''
};

// Define the Yup schema using Moment objects for date, time, and datetime fields
const appointmentSchema = Yup.object().shape({
  date: Yup.string().required(),
  doctor: Yup.string().required(),
  email: Yup.string().required(),
  injury: Yup.string().required(),
  name: Yup.string().required(),
  number: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.string().required(),
  tender_number: Yup.string().required(),
  id: Yup.string().required(),
  tender_closetime: Yup.mixed().required(), // Allow Moment objects or null
  opening_date: Yup.mixed().required(), // Allow Moment objects or null
  Close_date: Yup.mixed().required(), // Allow Moment objects or null
  Remarks: Yup.string().required(),
  prebid_closetime: Yup.mixed().required(), // Allow Moment objects or null
  prebid_closedate: Yup.mixed().required(), // Allow Moment objects or null
  prebid_time: Yup.mixed().required(), // Allow Moment objects or null
  prebid_date: Yup.mixed().required() // Allow Moment objects or null
});

const AppointmentForm = ({
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel
}: Props) => {
  const [tenderDocument, setTenderDocument] = useState([]);
  const [corrigendums, setCorrigendums] = useState([]);

  const formik = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      onSubmit({ ...form, fromTo: `${form.from} - ${form.to}` });
      formik.resetForm();
    }
  });

  const handleTenderDocumentChange = ({ fileList }) => setTenderDocument(fileList);

  const handleCorrigendumChange =
    (index: number) =>
    ({ fileList }) => {
      const newCorrigendums = [...corrigendums];
      newCorrigendums[index].file = fileList;
      setCorrigendums(newCorrigendums);
    };

  // const handleTenderDocumentChange = ({ fileList }) => {
  //   const file = fileList[0];
  //   if (file) {
  //     const fileNameParts = file.name.split('.');
  //     const fileExtension = fileNameParts.pop();
  //     const timestamp = new Date().getTime(); // Current timestamp in milliseconds
  //     const sanitizedFileName = `${fileNameParts
  //       .join('_')
  //       .replace(/\s+/g, '_')}_${timestamp}.${fileExtension}`;
  //     setTenderDocument([{ ...file, name: sanitizedFileName }]);
  //   } else {
  //     setTenderDocument(fileList);
  //   }
  // };

  // const handleCorrigendumChange =
  //   (index: number) =>
  //   ({ fileList }) => {
  //     const newCorrigendums = [...corrigendums];
  //     const file = fileList[0];
  //     if (file) {
  //       const fileNameParts = file.name.split('.');
  //       const fileExtension = fileNameParts.pop();
  //       const timestamp = new Date().getTime(); // Current timestamp in milliseconds
  //       const sanitizedFileName = `${fileNameParts
  //         .join('_')
  //         .replace(/\s+/g, '_')}_${timestamp}.${fileExtension}`;
  //       newCorrigendums[index].file = [{ ...file, name: sanitizedFileName }];
  //     } else {
  //       newCorrigendums[index].file = fileList;
  //     }
  //     setCorrigendums(newCorrigendums);
  //   };

  const addCorrigendum = () => {
    const newCorrigendums = [...corrigendums, {}]; // Add an empty object for new corrigendum
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const removeCorrigendum = (index: number) => {
    const newCorrigendums = corrigendums.filter((_, i) => i !== index);
    setCorrigendums(newCorrigendums);
    formik.setFieldValue('corrigendums', newCorrigendums); // Update Formik's values
  };

  const normFile = (e: any) => (Array.isArray(e) ? e : e && e.fileList);

  useEffect(() => {
    formik.setFieldValue('corrigendums', corrigendums);
  }, [corrigendums]);

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      prebid_date: fieldsValue['prebid_date'].format('YYYY-MM-DD'),
      tender_closetime: fieldsValue['tender_closetime'].format('HH:mm:ss'),
      prebid_time: fieldsValue['prebid_time'].format('HH:mm:ss'),
      prebid_closetime: fieldsValue['prebid_closetime'].format('HH:mm:ss'),
      prebid_closedate: fieldsValue['prebid_closedate'].format('YYYY-MM-DD'),
      opening_date: fieldsValue['opening_date'].format('YYYY-MM-DD'),
      Close_date: fieldsValue['Close_date'].format('YYYY-MM-DD'),
      tender_document: tenderDocument[0]?.name,
      corrigendums: corrigendums.map((item) => ({
        title: item.title,
        document: item.file[0]?.name
      }))
    };

    // console.log(values, 'VALUES FOR PAYLOAD');

    try {
      const response = await axios.post(`${API_BASE_URL}/aiims_tender_table/add`, values, {
        withCredentials: true
      });

      console.log(response, 'tender response');

      if (response.status === 200 || response.status === 201) {
        alert('Submitted successfully!');
        onCancel();
        // onSubmit(appointment);
      } else {
        console.error('Unexpected response status:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error: unknown) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  // console.log(corrigendums, 'CORRIRI');

  return (
    <Card>
      <Form
        name='basic'
        className='login-form'
        autoComplete='off'
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label='Tender Number'
          name='tender_number'
          rules={[{ required: true, message: 'Please input your Tender Number' }]}
        >
          <Input placeholder='Tender Number' />
        </Form.Item>
        <Form.Item
          name='tender_type'
          label='Tender Type'
          rules={[{ required: true, message: 'Please select Tender Type!' }]}
        >
          <Select placeholder='select your Tender Type'>
            <Option value='Closed Tender'>Closed Tender</Option>
            <Option value='Open Tender'>Open Tender</Option>
            <Option value='OPEN (RE)Tender'>OPEN (RE)Tender</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='prebid_date'
          label='Pre-Bid Start Date'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Start Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='prebid_time'
          label='Pre-Bid Start Time'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Start Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='prebid_closedate'
          label='Pre-Bid Close Date'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Close Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='prebid_closetime'
          label='Pre-Bid Close Time'
          rules={[
            {
              type: 'object' as const,
              required: false,
              message: 'Please input your Pre-Bid Close Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='status'
          label='Tender Status'
          rules={[{ required: true, message: 'Please select Tender Status!' }]}
        >
          <Select placeholder='select your Tender Status'>
            <Option value='Open'>Open</Option>
            <Option value='Closed'>Closed</Option>
            <Option value='Canceled'>Canceled</Option>
            <Option value='Suspend'>Suspend</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='opening_date'
          label='Tender Opening Date'
          rules={[
            { type: 'object' as const, required: true, message: 'Please input your Opening Date!' }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='Close_date'
          label='Tender Close Date'
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please input your Tender Close Date!'
            }
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='tender_closetime'
          label='Tender Close Time'
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please input Tender Closing Time!'
            }
          ]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label='Tender Document'
          name='tender_document'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Please upload Tender Document' }]}
        >
          <Upload.Dragger
            name='tender_document'
            listType='picture'
            action={`${API_BASE_URL}/uploaddoc/`}
            fileList={tenderDocument}
            onChange={handleTenderDocumentChange}
            maxCount={1}
          >
            {tenderDocument.length === 0 && (
              <>
                <p className='ant-upload-drag-icon'>
                  <i className='icofont icofont-file-pdf' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Support for a single upload.</p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>

        <Form.List name='corrigendums'>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    marginBottom: 8
                  }}
                  direction='vertical'
                >
                  <Form.Item
                    {...restField}
                    label={`Corrigendum ${index + 1}`}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    rules={[{ required: true, message: 'Missing corrigendum title' }]}
                    style={{ width: '100%' }}
                  >
                    <Input
                      placeholder='Corrigendum Title'
                      defaultValue={corrigendums[index]?.title} // Set default value
                      onChange={(e) => {
                        const newCorrigendums = [...corrigendums];
                        newCorrigendums[index].title = e.target.value;
                        setCorrigendums(newCorrigendums);
                      }}
                    />
                  </Form.Item>

                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'document']}
                      fieldKey={[fieldKey, 'document']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[{ required: true, message: 'Missing corrigendum file' }]}
                      style={{ flexGrow: 1 }}
                    >
                      <Upload
                        name='document'
                        listType='picture'
                        action={`${API_BASE_URL}/api/upload/`}
                        fileList={corrigendums[index]?.file}
                        onChange={handleCorrigendumChange(index)}
                        maxCount={1}
                        // style={{ textWrap: 'wrap' }}
                      >
                        <Button type='primary'>Upload</Button>
                      </Upload>
                    </Form.Item>

                    <MinusCircleOutlined
                      type='danger'
                      onClick={() => {
                        remove(name);
                        removeCorrigendum(index);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </Space>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                    addCorrigendum();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Corrigendum
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.Item
          name='tender_description'
          label='Tender Description'
          rules={[{ message: 'Please input your Title Description' }]}
        >
          <Input placeholder='Title Description' />
        </Form.Item>
        <Form.Item
          label='Remarks'
          name='Remarks'
          rules={[{ message: 'Please input your Remarks' }]}
        >
          <Input.TextArea placeholder='Remarks' />
        </Form.Item>
        <Form.Item>
          <div className='d-flex justify-content-between buttons-list settings-actions'>
            <Button type='primary' htmlType='submit'>
              {submitText}
            </Button>
            <Button danger onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AppointmentForm;
