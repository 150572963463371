import React, { useEffect } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout as logoutAction,
  fetchUserDetails,
  userLogout,
  deleteCookie
} from '../../../redux/auth/action';
import axios from 'axios';
import { API_BASE_URL } from '../../../apiService/apiservices';
import { AppState } from '../../../redux/store';

const accountItems = [{ text: 'Log Out', icon: 'icofont-logout', route: '/public/sign-in' }];

const SettingsDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.auth.user);

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );
      if (response.status === 200) {
        userLogout();
        deleteCookie('sid_enco_data_ga');
        // dispatch(logoutAction());
        history.push('/public/sign-in');
      } else {
        alert('Failed to log out. Please try again.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred while logging out. Please try again.');
    }
  };

  const accountMenu = (
    <Menu style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <Menu.Item className='action-item' key={index}>
          {item.route === '/public/sign-in' ? (
            <div className='d-flex w-100' onClick={handleLogout}>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </div>
          ) : (
            <NavLink className='d-flex w-100' to={item.route} replace>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/user-40-2.jpg`}
        />
        <span>{user?.username || 'User'}</span>
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
